import React from 'react'
import PropTypes from 'prop-types'

import { rhythm } from '../utils/typography'
import Header from './Header'
import Footer from './Footer'

const Layout = ({ location, title, children }) => {
  return (
    <div>
      <Header location={location} title={title} />

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '90%',
          maxWidth: '900px',
          marginLeft: 'auto',
          marginRight: 'auto',
          padding: rhythm(3 / 4)
        }}
      >
        <main>{children}</main>
      </div>

      <Footer title={title} />
    </div>
  )
}

Layout.propTypes = {
  location: PropTypes.any,
  title: PropTypes.any,
  children: PropTypes.any
}

export default Layout
