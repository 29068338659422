import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { scale, rhythm } from '../utils/typography'

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
        }
      }
    }
  `)

  const { author } = data.site.siteMetadata

  return (
    <footer style={{
      textAlign: 'center',
      ...scale(-0.5),
      padding: rhythm(3 / 4)
    }}>
      © {new Date().getFullYear()} { author.name }
    </footer>
  )
}

export default Footer
