import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery, Link } from 'gatsby'

import { rhythm, scale } from '../utils/typography'

const Header = ({ location }) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const { title } = data.site.siteMetadata

  // eslint-disable-next-line no-undef
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  const Title = (
    <Link
      to={'/'}
      style={{
        color: '#1b262c',
        textDecoration: 'none'
      }}
    >
      <img src={'/icon.svg'} style={{
        height: '15px',
        width: '15px',
        margin: `0 ${rhythm(1 / 5)}`
      }} alt={title}/>

      {title}
    </Link>
  )

  const style = {
    ...scale(-0.2),
    marginBottom: 0,
    marginTop: 0,
    color: '#000'
  }

  if (location.pathname === rootPath) {
    header = (
      <h1
        style={style}
      >
        { Title }
      </h1>
    )
  } else {
    header = (
      <h3
        style={style}
      >
        { Title }
      </h3>
    )
  }

  return (
    <header
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '90%',
        maxWidth: '1200px',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: rhythm(3 / 4)
      }}
    >
      {header}
    </header>
  )
}

Header.propTypes = {
  location: PropTypes.any.required
}

export default Header
